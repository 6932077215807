import React, { useState } from 'react'
import styled from 'styled-components'
import Breadcrumbs from 'components/hero-image/breadcrumbs'
import { Helmet } from 'react-helmet'
import Content from 'components/content-full-width'
import Layout from 'components/layout'
import ProductTable from 'components/new/product-table'
import { graphql } from 'gatsby'
import { useToggle } from 'hooks/use-toggle'
import Modal from 'components/modal/modal'
import QuoteForm from 'components/forms/quote'
import BreadcrumbsScript from 'components/scripts/breadcrumbs'

const breadcrumbs = [
  {
    name: 'Home',
    schemaName: 'Hutson Inc',
    link: '/',
  },
  {
    name: 'Construction Equipment',
    link: '/construction/',
  },
  {
    name: 'Attachments',
    link: '/construction/attachments/',
  },
  {
    name: 'Plate Compactors',
    link: '/construction/attachments/plate-compactors/',
  },
]

const PlateCompactorsAttachmentsPage = ({ data, location }) => {
  const { allDeereConstructionAttachment } = data
  const [modalOpen, toggleModal] = useToggle(false)
  const [selectedProduct, setSelectedProduct] = useState('')

  const handleProductOnClick = e => {
    const productName = e.target.getAttribute('data-name')
    setSelectedProduct(productName)
    toggleModal()
  }

  const productTables = allDeereConstructionAttachment.nodes.reduce((acc, node) => {
    const matchIndex = acc.findIndex(obj => obj.title === node.subcategory)
    if (matchIndex === -1) {
      acc.push({
        title: node.subcategory,
        items: [
          {
            name: node.name,
            tableData: node.tableData,
            onClick: handleProductOnClick,
          },
        ],
      })
    } else {
      acc[matchIndex].items.push({
        name: node.name,
        tableData: node.tableData,
        onClick: handleProductOnClick,
      })
    }
    return acc
  }, [])
  return (
    <Layout kind='white'>
      <Helmet>
        <title>John Deere Plate Compactors | Hutson Inc</title>
      </Helmet>

      <BreadcrumbsScript breadcrumbs={breadcrumbs} />

      <Content>
        <Header>
          <Title>Plate Compactors</Title>
          <StyledBreadcrumbs breadcrumbs={breadcrumbs} />
        </Header>
        {productTables.map((table, key) => (
          <CategorySection key={table.title || key}>
            {table.title && <CategoryTitle>{table.title}</CategoryTitle>}
            <ProductTable items={table.items} />
          </CategorySection>
        ))}
      </Content>

      <Modal toggle={toggleModal} isOpen={modalOpen}>
        <QuoteForm
          toggleModal={toggleModal}
          productName={selectedProduct}
          productType='construction-attachment'
          submitButtonClass='submit-aftermarket-quote'
          pageURL={location.href}
          equipmentBrand='John Deere'
          equipmentCondition='new'
        />
      </Modal>
    </Layout>
  )
}

const Header = styled.div``

const Title = styled.h1`
  margin: 0;
`

const CategorySection = styled.div`
  margin-top: 36px;
`

const CategoryTitle = styled.h2`
  margin: 0 0 24px;
`

const StyledBreadcrumbs = styled(Breadcrumbs)`
  color: #000;
`

export const pageQuery = graphql`
  query plateCompactorsAttachmentsQuery($category: String! = "Plate Compactors") {
    allDeereConstructionAttachment(filter: { categories: { in: [$category] } }) {
      nodes {
        id
        categories
        subcategory
        name
        tableData {
          property
          data
        }
      }
    }
  }
`

export default PlateCompactorsAttachmentsPage
